@import url(https://fonts.googleapis.com/css?family=Lato|Noto+Sans+JP&display=swap);
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', 'Noto Sans JP', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

:root {
  /* color */
  --color-white: #ffffff;
  --color-bitkey: #029fac;
  --color-key: #03a9f4;
  --color-admin-key: #2f80ed;
  --color-error: #e91e63;
  --color-sub: #f29700;
  --color-gray-1: #333333;
  --color-gray-2: #4f4f4f;
  --color-gray-3: #828282;
  --color-text: var(--color-gray-1);
  --color-background-gray: #eceff1;

  /* background */
  --background-error: linear-gradient(
      0deg,
      rgba(233, 30, 99, 0.07),
      rgba(233, 30, 99, 0.07)
    ),
    #ffffff;
  --background-admin: #f3f8ff;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

